// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "bootstrap"
import "../stylesheets/application"
//import 'bootstrap-icons/font/bootstrap-icons.css'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'

import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'
//carousel
import owlCarousel from "owl.carousel2";
import "owl.carousel2/dist/assets/owl.carousel.css";

import Swal from 'sweetalert2';
window.Swal = Swal;

Rails.start()
//Turbolinks.start()
ActiveStorage.start()
ActiveStorageDragAndDrop.start()


require('jquery')
require("jquery-ui")
require("jquery-mask-plugin")
require("@nathanvda/cocoon")
global.toastr = require("toastr")

$(function(){
  console.log('Loaded.');
});

$(function() {
    $(".images").sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


    $( "#sortable" ).sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


  });
  $.jMaskGlobals.watchDataMask = true;
require("trix")
require("@rails/actiontext")
import "controllers"

window.initMap = function(...args){
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}